// Mixins
// --------------------------------------------------

// Utilities
@import "mixins/hide-text.less";
@import "mixins/opacity.less";
@import "mixins/image.less";
@import "mixins/labels.less";
@import "mixins/reset-filter.less";
@import "mixins/resize.less";
@import "mixins/responsive-visibility.less";
@import "mixins/size.less";
@import "mixins/tab-focus.less";
@import "mixins/reset-text.less";
@import "mixins/text-emphasis.less";
@import "mixins/text-overflow.less";
@import "mixins/vendor-prefixes.less";

// Components
@import "mixins/alerts.less";
@import "mixins/buttons.less";
@import "mixins/panels.less";
@import "mixins/pagination.less";
@import "mixins/list-group.less";
@import "mixins/nav-divider.less";
@import "mixins/forms.less";
@import "mixins/progress-bar.less";
@import "mixins/table-row.less";

// Skins
@import "mixins/background-variant.less";
@import "mixins/border-radius.less";
@import "mixins/gradients.less";

// Layout
@import "mixins/clearfix.less";
@import "mixins/center-block.less";
@import "mixins/nav-vertical-align.less";
@import "mixins/grid-framework.less";
@import "mixins/grid.less";


.shadow(@radious:3px, @color:rgba(0,0,0,0.35)){
    box-shadow: 0 2px @radious @color;    
}
.text-shadow(@y:2px){
    text-shadow: 0 @y 3px rgba(0,0,0,0.35);    
}
.inset-shadow(){
    -webkit-box-shadow: inset 0px 100px 70px -80px rgba(0,0,0,0.1), inset 0 -100px 70px -80px rgba(0,0,0,0.1);
    -moz-box-shadow: inset 0px 100px 70px -80px rgba(0,0,0,0.1), inset 0 -100px 70px -80px rgba(0,0,0,0.1);
    box-shadow: inset 0px 100px 70px -80px rgba(0,0,0,0.1), inset 0 -100px 70px -80px rgba(0,0,0,0.1);
}
.inset-shadow-light(){
    -webkit-box-shadow: inset 0px 0px 80px 0 rgba(0,0,0,0.1);
    -moz-box-shadow: inset 0px 0px 80px 0 rgba(0,0,0,0.1);
    box-shadow: inset 0px 0px 80px 0 rgba(0,0,0,0.1);
}
/*!
 * Bootstrap v3.3.5 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "variables.less";
@import "mixins.less";

// Reset and dependencies
@import "normalize.less";
@import "print.less";
@import "glyphicons.less";

// Core CSS
@import "scaffolding.less";
@import "type.less";
@import "code.less";
@import "grid.less";
@import "tables.less";
@import "forms.less";
@import "buttons.less";

// Components
@import "component-animations.less";
@import "dropdowns.less";
@import "button-groups.less";
@import "input-groups.less";
@import "navs.less";
@import "navbar.less";
@import "breadcrumbs.less";
@import "pagination.less";
@import "pager.less";
@import "labels.less";
@import "badges.less";
@import "jumbotron.less";
@import "thumbnails.less";
@import "alerts.less";
@import "progress-bars.less";
@import "media.less";
@import "list-group.less";
@import "panels.less";
@import "responsive-embed.less";
@import "wells.less";
@import "close.less";

// Components w/ JavaScript
@import "modals.less";
@import "tooltip.less";
@import "popovers.less";
@import "carousel.less";

// Utility classes
@import "utilities.less";
@import "responsive-utilities.less";


@import "slick.less";

body{    
    background:@body-bg;
    color:@text-color;
    position:relative;
    background: @body-bg url("@{img-path}/bg.jpg") center -200px no-repeat;
    background-attachment: fixed;

    h1{
        color:@main-color;
    }
}
.bg-ecrue{
/*    background: url("@{img-path}/groovepaper.png");
    z-index:9999;*/
    border-bottom:1px solid @main-color;
}
.box{

    max-width:1400px;
}
.header, .footer{
    display:block;
    float:left;
    width:100%;
    background:@body-bg!important;
    
    .box{
        padding:0 20px;
    }
}

.header{
    .form-inline{
        input,button{
            
            
            

        }
        
    }
}

.strip{
    .shadow();
    background:  @main-color; 
    display:block;
    float:left;
    width:100%;
    text-align: center;
    font-family: @font-bold;    
    text-transform:uppercase;
    color:@body-bg;
    li{
        display:block;
        float:left;
        width:100%;
        position:relative;
        @media (max-width: @screen-md-min){
            font-size:18px;
            padding:2px;
        }
        @media (min-width: @grid-float-breakpoint) {
            display: inline-block;
            float:none;
            padding:5px 15px;
            font-size: 20px;
            width:auto;
        }
        a{  
            color:@text-color;
            &:hover, &:active{
                background: @body-bg;
                color:@main-color;
            }
        }
        &.active{
            background: @body-bg;
            color:@main-color;
            a, a:active, a:hover{
                background: @body-bg;
                color:@main-color;
            }
        }
    }   


}
a.active{
    font-family: @font-bold;
}
.lang{
    font-size:24px;
}
.green{
    color:@main-color;
    .text-shadow();
}
.bg{

}
.border{
    border:1px solid transparent;
    margin-top:20px;

    outline:3px solid @main-color;
    .shadow();
}
.border-top{
    border-top:1px solid @brand-primary;
    padding:15px;
    font-size:16px;    
}
.caption{
    .text-shadow();
    position:absolute;
    bottom:30px;
    width:100%;
    left:0;
    text-align: center;
    text-transform: uppercase;    
    bottom:0;
    font-family: @font-bold;
    font-size:@font-size-h2;
    color: @main-color;
}
.dark{
    p{
        color:#fff;
    }
}
.light{
    background:#fff;
    border:1px solid @brand-primary;
    h2{
        
        margin: ceil((@grid-gutter-width / -2));
        margin-top:0;
        margin-bottom:0;        
        text-shadow: none;
        color:@main-color;
    }
}
.row.margin{
    padding-top:150px;
    padding-bottom:150px;
}
.button-big{
    color:#fff;
    font-size:36px;
    font-family: @font-bold;
    background: @main-color;
    padding:30px 40px;
    text-transform: uppercase;
    text-shadow: 0 0 3px #000;
    .shadow();
    &:hover{
        text-decoration: none;
        outline:2px solid #fff;
    }
}
.button-personalise{
    background:@main-color;
    padding:8px 30px;
    text-align: center;
    text-transform: uppercase;
    display: block;
    margin:20px auto;   
    position:relative;
    color:#fff;
    font-family: @font-bold;
    font-size:@font-size-large;
    height:45px;
    width:260px;
    border:0;

    .text-shadow(0); 
    &:hover{
        color:@body-bg;
    }
}
.ico-rec{
    display:block;
    position:absolute;
    top:0;
    left:0;
    background:#ed1c24;
    width:15px;
    height:15px;

    &.blue{
        background:#66cff1;
        top:15px;
    }
    &.yellow{
        background:#fff200;
        top:30px;
    }
}

.grid[data-columns]::before {
    content: '3 .column.size-1of3';
}
.gallery{  

    a{
        max-width: 33.333332%;
        position: relative;            
        height:auto;
        margin:0;
        padding:0;
        float:left;
        display:block;
        border-left:1px solid @body-bg;
        border-bottom:1px solid @body-bg;
        box-sizing: border-box;
        img{
            display:inline-block;
            max-width: 100%;
        }
        @media (max-width: @screen-xs-max){
            max-width: 100%;
        }
    }
}
.footer{
    ul{
        padding:0;
        margin:0;
        margin-bottom:20px;
        li{
            padding:5px 0;
            margin:0;
            list-style: none;
        }
    }
}
.simple-button{
    border:0;
    background: none;

}
.inner-shadow{
    .inset-shadow();
}
#modal{
    z-index:99999;

}
.modal-body{
    img{
        .img-responsive;
    }
}
.pic-fade{

}
.overlay{
    display:block;
    position:absolute;
}

.pad-off{
    padding: 0;
    //border:1px solid #fff;
}
.text-center{
    display:table-cell;
    vertical-align: middle;
}


////////////////////Product container

.product-list{
    list-style:none;
    padding:0;
    margin:0;
}
.product-container, .product{
    border-left:1px solid #fff;
    border-right:1px solid #fff;
    border-bottom:1px solid @main-color;
    text-align:center;
   
        
   
    &.active{
        //border:1px solid #fff;
        z-index:999;
        .shadow(30px,rgba(0,0,0,0.2));
        .content_price{
            background: transparent;
        }
        .right-block{
            box-shadow: none;
        }
    }
}
.content_price{
    background:@main-color;
    color:@text-color;
    padding:5px;
    font-size:@font-size-h3;
    font-family: 'proxima_nova_rgbold';

}
.left-block{
    padding-top:40px;
}
.right-block{
    .inset-shadow-light();
}
h5{
    margin:20px 0 10px 0;
    font-family: @font-bold;
}
.product-name{
    color:@text-color;
    text-transform:uppercase;
    padding:5px;
    font-size:@font-size-h4;

}
.product-flags{
    text-transform:uppercase;
    font-family: @font-bold;
    font-size:18px;
    position:absolute;
    left:0;
    top:0;
    width:100%;

    span{
        display:block;
        height:80px;
        background:url("@{img-path}/promo.svg") center bottom no-repeat;
        padding: 20px;

        &.new{
            color:red;
            float:left;
        } 
        &.promo{
            color:#53c8ff;
            float:right;
        }

    }

}
.buy{
    width:100%;
    text-align: center; 
    margin-bottom:30px;
} 
.more{
    font-family: @font-regular;
    font-size:32px;
    letter-spacing: 2px;
    line-height: 1.0;
    position: absolute;
    display: block;
    bottom:0;
    right:0;
    padding:10px;            
    text-align: right;

}


.rotate{
    position:absolute;
    left:0;
    top:0;
    margin:20px 40px;
}

.product-form{
    label, select{
        padding:10px;
        border: 1px solid @gray-light;
        color: @body-bg;
        font-size:24px;
    }
    input{
        border:0;
        padding:0;
    }
}
.padding{
    padding:20px;
}
.table-simple{
    border:1px solid @gray-light;
    border-collapse: collapse;
    width:100%;
    font-size:24px;
    font-family: @font-regular;
    td{
        border:1px solid @gray-light;
        vertical-align: middle;
        text-align: left;
        &.price{
            text-align: center;
            font-family: @font-bold;
            font-size:32px;
            background: @main-color;
        }
    }
    input, select, button{
        border:0;
        padding:10px;
        width:100%;
        margin:0;

    }    
    label{
        padding:0 20px;
        margin:0;
        input{
            width:auto;
        }
    }

}
.two-lists{
    ul{
        list-style: none;
        padding:20px 0;
        font-size:20px;
        li{
            text-align: left;
            padding:5px;
        }
    }
}
.info{
    h3{
        font-size:@font-size-h2;
        padding:20px 0;
        &:before, &:after{
            content:"";

        }
    }
}
.border-right{
    border-right:1px solid @gray-light;
}
.title{
    padding:10px 30px;
    font-size:@font-size-h4;
    margin:0;
    line-height: 1.4;
}